<template>
  <component :color="color" :is="icon" class="item-i icon" :class="size" :active="active" />
</template>

<script>
import { defineAsyncComponent, shallowRef, watch } from "vue";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "c24",
    },
    active: {
      type: Boolean,
      default: true,
    },
    color: [Boolean, Object],
  },
  setup(props) {
    const icon = shallowRef(null);

    const loadComponent = async (componentName) => {
      icon.value = defineAsyncComponent(() => import(`@/components/icons/iconsList/${componentName}.vue`));
    };

    watch(
      () => props.name,
      (newName) => {
        if (newName) {
          loadComponent(newName);
        }
      },
      { immediate: true }
    );

    return {
      icon,
    };
  },
};
</script>
