<template>
    <transition name="warning-container">
        <div class="warning-popup warning d-flex justify-content-end align-items-start">
            <div class="warning-container col-xl-6 col-lg-8 col-10 neue-roman" :class="this.warningType">
                <div class="warning-wrapper d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="warning-picture">
                            <div>
                                <Icon :name="this.warningType" size="c32" />
                            </div>
                        </div>
                        <div class="warning-text-info">
                            <div class="warning-text">
                                {{ this.warningText ? this.warningText : '' }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <i class="item-i error-close c20 pointer" @click="this.closePopup()"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";
export default {
    components: {
        Icon
    },
    data() {
        return {}
    },
    props: {
        warningType: [String],
        warningText: [String],
    },
    methods: {
        closePopup() {
            console.log('closePopup');
            this.$emit('close', {
            })
        }
    },
}
</script>

<style lang="scss">
    .warning-popup {
        position: fixed;
        z-index: 4000;
        padding-top: 117px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        outline: none;
        overflow: auto;
        opacity: 0;
        animation: ani 0.3s forwards;
        padding-right: 40px;
    }

    @keyframes ani {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }

    .warning-container {
        color: #fff;
        padding: 20px;
        border-radius: 15px;

        @media (min-width: 992px) {
            max-width: 500px;
        }
    }

    .warning-picture,
    .warning-text-info {
        margin-right: 20px;
    }

    .warning-info {
        background: $color-grey!important;
    }

    .warning-money {
        background: $menu-bg-search!important;
    }

    .warning-keyt {
        background: $dark-orange!important;
    }
</style>