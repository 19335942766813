<template>
    <div v-if="this.data.length > 10" class='d-flex align-items-center justify-content-between col-12'>
        <div class="d-flex align-items-center flex-column flex-md-row flex-wrap justify-content-between col-12 p-0">
            <div class="col-12 col-md-3" id="c-pg-1" :class="this.$store.state.resize.w < 992 ? 'mb-2 text-center' : ''">
                {{ this.$t('components.service.pagination.1') }} {{ this.calcLeftValue() }} - {{ this.calcRightValue() }} ({{ this.$t('components.service.pagination.2') }} {{ this.data.length }})
            </div>
            <div class="d-flex align-items-center justify-content-center" :class="this.$store.state.resize.w < 992 ? 'mb-2' : ''">
                <ul class="pagination pagination-sm mb-0" v-if="this.buttons.length > 0">
                    <li v-for="row in this.buttons" :key="row"
                    class="page-item"
                    :id="'c-pg-d' + row"
                    :class="{'active': row == this.page}" 
                    v-on:click='() => {this.changePage(row)}' 
                    aria-current="page">
                        <span :id="'c-pg-dt' + row" class="page-link">{{row}}</span>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center col-4 col-md-2">
                <div class="col-8 col-md-6">{{ this.$store.state.resize.w < 992 ? this.$t('components.service.pagination.4') : this.$t('components.service.pagination.3') }}</div>
                <SelectSimple
                    id='amount_group'
                    :keywords.sync='this.amount_list'
                    :dataValue='this.amount_group'
                    @updateParentState="this.updateAmount"
                    :hotUpdate="true"
                    :isPaginationSelect="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SelectSimple from '@/components/service/SelectSimple';
export default {
    components: {
        SelectSimple
    },
    props: {
        data: [Array, Object],
        count: {
            type: [Number, Boolean],
            default: 10,
        },
        max: {
            type: [Number, Boolean],
            default: 6,
        },
        savePage: {
            type: Boolean,
        }
    },
    watch: {
        data: {
            handler: function(newData, oldData) {
                    // console.log(oldData)
                    // console.log(newData)
                // if (newData != oldData) { 
                    // console.log('handler data', this.data);
                    // this.pages = false;
                    this.content = {};
                    this.getPages();
                    this.setData();
                    // console.log('handler content', this.content);

                    if (this.savePage && this.pages >= this.page) {
                        this.page = this.page;
                    } else {
                        this.page = 1;
                    }
                    this.setButtons();
                    this.returnContent();
                    // console.log('handler this.content', this.content);
                // }
            },
            deep: true,
        },
    },
    data() {
        return {
            pages: false,
            page: 1,
            content: {},
            buttons: [],
            amount_group: '0',
            amount_list: [
                {code: '0', value: '10'},
                {code: '1', value: '50'},
                {code: '2', value: '100'}
            ],
            amount_val: 10,
        }
    },
    mounted() {
        // console.log('mounted pagination', this.data);
        if (this.data) {
            this.pages = false;
            this.content = {};
            this.getPages();
            this.setData();
            this.page = 1;
            this.setButtons();
            this.returnContent();
        }
    },
    methods: {
        returnContent() {
            // console.log('returnContent this.page', this.page);
            // console.log('returnContent this.content', this.content);
            // console.log(this.content[this.page])
            // let res = Object.assign({}, this.content[this.page]);
            let res = this.content[this.page];
            // console.log('returnContent res', res);
            this.$emit('updateContentState', res);
        },
        getPages() {
            this.pages = Math.ceil(this.data.length / this.amount_val);
        },
        setData() {
            let tmp = 0;
            this.content = {};
            for (let i = 0; i < this.pages; i ++  ) {
                this.content[i + 1] = this.data.slice(tmp, this.amount_val + tmp);
                tmp += this.amount_val;
            }
        },
        changePage(i) {
            if (i == '<<') {
                i = 1;
            } else if (i == '>>') {
                i = this.pages;
            }
            this.page = i;
            this.setButtons();
            this.returnContent();
            this.calcLeftValue();
            this.calcRightValue();
        },
        calcLeftValue() {
            return Number((this.page - 1) * this.amount_val + 1);
        },
        calcRightValue() {
            if (this.amount_val > this.data.length) {
                return this.data.length;
            }

            if (this.page == 1) {
                return this.amount_val;
            }

            const rightValue = Number(this.page * this.amount_val);

            return rightValue > this.data.length ? this.data.length : rightValue;
        },
        setButtons() {
            this.buttons = [];
            if (this.pages <= this.max && this.pages > 1) {
                for (let key in this.content) {
                    this.buttons.push(key);
                }
                return;
            } else if (this.pages > this.max && this.pages > 1) {
                this.buttons.push('<<');
                let c = this.max/2;
                for (let key in this.content) {
                        if (this.page - key <= c && this.page - key > 0) {
                            this.buttons.push(+key);
                        } else if (key == this.page) {
                            this.buttons.push(this.page);
                        } else if (key - this.page <= c &&  key - this.page > 0  ) {
                            this.buttons.push(+key);
                        }

                }
                this.buttons.push('>>');
            }
        },
        updateAmount(arg) {
            setTimeout(() => {
                const amountCode = arg.value;
                this.amount_group = amountCode;
                
                const val = this.amount_list.find(item => item.code == amountCode).value;

                this.amount_val = val ? Number(val) : '';

                if (this.amount_val) {
                    this.changePage(1);
                    this.getPages();
                    this.setData();
                    this.returnContent();
                    this.setButtons();
                    this.calcLeftValue();
                    this.calcRightValue();
                }
            }, 500);
        }
    },    
}
</script>

<style lang="scss">
.pagination > li > span
{
    background-color: white;
    color: $color-grey;
    cursor: pointer;
}

.pagination > li > span:focus,
.pagination > li > span:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: white;
    background-color: $color-warning;
    border-color: $color-warning;
}

.pagination > .active > span
{
    color: white;
    background-color: $color-warning !Important;
    border: solid 1px $color-warning !Important;
    filter: saturate(200%);
}

.pagination > .active > span:hover
{
    background-color: $color-warning !Important;
    border: solid 1px $color-warning;
}
</style>