<template>
    <div class="custom-loader-container" :style="this.fullPage ? '' : `position: relative; min-height: ${this.height}px; min-width: ${this.width}px;`">
        <Loading 
        style="z-index: 8000;" 
        :class="{'full_screen': this.fullPage}"
        :active.sync="true" 
        :can-cancel="false"  
        :is-full-page="this.fullPage"
        :height='this.height'
        :width='this.width'
        :loader='this.type'
        :color='this.metaColor'
        :opacity='0'/>
        <p id="blink" v-if="!this.no_text" :style="`color: ${this.metaColor};`" class="m-0 neue-bold">Loading</p>
    </div>
</template>

<script lang="js">
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    components: {
        Loading
    },
    props: {
        fullPage: [Boolean],
        type: {
            type: [String, Boolean],
            default: 'spinner'
        },
        height: {
            type: [Number, Boolean],
            default: 100
        },
        width: {
            type: [Number, Boolean],
            default: 100
        },
        no_text: {
            type: [Boolean],
            default: false
        }

    },
    data() {
        return {
            metaColor: process.env.VUE_APP_THEMECOLOR
        }
    }
}

</script>
<style lang="scss">

#blink {
  -webkit-animation: blink 2s linear infinite;
  animation: blink 2s linear infinite;
  z-index: 8001;
}
@-webkit-keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.1; }
  100% { opacity: 1; }
}
@keyframes blink {
    0% { opacity: 1; }
  50% { opacity: 0.1; }
  100% { opacity: 1; }
}

.custom-loader-container {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    z-index: 8000;
}
.full_screen {
    .vl-background {
        opacity: 0.5 !important;
    }
}
</style>