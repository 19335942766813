<template>
    <div class="py-3 w-100 col-12">
        <div class="col-12 d-flex flex-column flex-sm-row flex-wrap justify-content-between mb-3">
            <div
                class="d-flex flex-column pr-2"
                :class="{'w-100 mb-2': this.$store.state.resize.w < 576, 'col-4': this.$store.state.resize.w >= 576}"
            >
                <div class="neue-roman color-menu text-uppercase">{{ this.$t('views.ShowInfoVA.1') }}</div>
                <div class="d-flex justify-content-between py-1">
                    <span>{{ this.$t('views.ShowInfoVA.2') }}</span>
                    <span class="d-flex">
                        {{ this.sized(this.vaData.curr.owner_keyt_alias ?
                            this.vaData.curr.owner_keyt_alias :
                            this.vaData.curr.owner_keyt) 
                        }}
                        <Tooltip
                            class="pointer ml-2 d-flex justify-content-center align-items-center"
                            :htmlContent="true"
                            :content="$t('views.ShowInfoVA.26')"
                            :smart_icon="true"
                        >
                            <i
                                class="pointer item-i copy c18 cli_2 ml-2"
                                @click.stop="(event) => {this.copyText(event)}"
                            ></i>
                            <input
                                type='hidden'
                                :id='this.vaData.curr.owner_keyt_alias ? this.vaData.curr.owner_keyt_alias : this.vaData.curr.owner_keyt'
                                :value="this.vaData.curr.owner_keyt_alias ? this.vaData.curr.owner_keyt_alias : this.vaData.curr.owner_keyt"
                            />
                        </Tooltip>
                        <span
                            v-if="this.vaData.curr.explorer_link"
                            @click.stop="(event) => this.goToLink(event, this.vaData.curr.explorer_link)"
                            class="ml-2 d-flex justify-content-center align-items-center"
                        >
                            <Tooltip class="pointer ml-2" :smart_icon="true" :custom="'item-i ext-link c18'" :content="$t('views.ShowInfoVA.27')" />
                        </span>
                    </span>
                </div>
                <div class="d-flex justify-content-between lightgrey py-1">
                    <span>{{ this.$t('views.ShowInfoVA.3') }}</span>
                    <span>{{ this.vaData.curr.name }}</span>
                </div>
                <div class="d-flex justify-content-between py-1">
                    <span>{{ this.$t('views.ShowInfoVA.4') }}</span>
                    <span>{{ this.vaData.curr.currency }}</span>
                </div>
                <div class="d-flex justify-content-between lightgrey py-1">
                    <span>{{ this.$t('views.ShowInfoVA.12') }}</span>
                    <span>{{ this.vaData.curr.responsible == '0' ? this.$t('views.ShowInfoVA.13') : this.$t('views.ShowInfoVA.24') }}</span>
                </div>
                <div v-if="this.vaData.curr.responsible == '0'" class="d-flex justify-content-between py-1">
                    <span class="mr-2">{{ this.$t('views.ShowInfoVA.13') }}</span>
                    <span class="d-flex align-items-center">
                        <span class="text-end">
                            {{ this.vaData.curr.owner_name ? this.vaData.curr.owner_name : this.vaData.curr.uid }}
                        </span>
                        <span
                            @click.stop="(event) => this.goToLink(event, '/corr_info?uid=' + this.vaData.curr.uid)"
                            class="ml-2 d-flex justify-content-center align-items-center"
                        >
                            <Tooltip
                                class="pointer ml-2"
                                :custom="'item-i ext-link c18'"
                                :smart_icon="true"
                                :content="$t('views.ShowInfoVA.27')"
                            />
                        </span>
                    </span>
                </div>
            </div>
            <div
                class="d-flex flex-column pr-2" 
                :class="{'w-100 mb-2': this.$store.state.resize.w < 576, 'col-4': this.$store.state.resize.w >= 576}"
            >
                <div class="neue-roman color-menu text-uppercase">{{ this.$t('views.ShowInfoVA.5') }}</div>
                <div class="d-flex justify-content-between align-items-center py-1">
                    <span>{{ this.$t('views.ShowInfoVA.17') }}</span>
                    <span>
                        <i class="item-i c20" :class="this.vaData.curr.access_atm_buy == 1 ? 'check-maker-verification' : 'not-verification'"></i>
                    </span>
                </div>
                <div class="d-flex justify-content-between align-items-center lightgrey py-1">
                    <span>{{ this.$t('views.ShowInfoVA.18') }}</span>
                    <span>
                        <i class="item-i c20" :class="this.vaData.curr.access_atm_sell == 1 ? 'check-maker-verification' : 'not-verification'"></i>
                    </span>
                </div>
                <div class="d-flex justify-content-between align-items-center py-1">
                    <span>{{ this.$t('views.ShowInfoVA.19') }}</span>
                    <span>
                        <i class="item-i c20" :class="this.vaData.curr.access_offer == 1 ? 'check-maker-verification' : 'not-verification'"></i>
                    </span>
                </div>
                <div class="d-flex justify-content-between align-items-center lightgrey py-1">
                    <span>{{ this.$t('views.ShowInfoVA.20') }}</span>
                    <span>
                        <i class="item-i c20" :class="this.vaData.curr.access_p2p == 1 ? 'check-maker-verification' : 'not-verification'"></i>
                    </span>
                </div>
            </div>
            <div
                class="d-flex flex-column"
                :class="{'w-100': this.$store.state.resize.w < 576, 'col-4': this.$store.state.resize.w >= 576}"
            >
                <div class="neue-roman color-menu text-uppercase">{{ this.$t('views.ShowInfoVA.6') }}</div>
                <div class="py-1">{{ this.vaData.curr.mission }}</div>
            </div>
        </div>
        <div class="col-12 d-flex flex-column flex-sm-row flex-wrap justify-content-between">
            <div
                class="d-flex flex-column pr-2"
                :class="{'w-100 mb-2': this.$store.state.resize.w < 576, 'col-4': this.$store.state.resize.w >= 576}"
            >
                <div class="neue-roman color-menu text-uppercase">{{ this.$t('views.ShowInfoVA.7') }}</div>
                <div class="d-flex justify-content-between py-1">
                    <span>{{ this.$t('views.ShowInfoVA.9') }}</span>
                    <span>{{ this.vaData.curr.precision }}</span>
                </div>
                <div class="d-flex justify-content-between lightgrey py-1">
                    <span>{{ this.$t('views.ShowInfoVA.11') }}</span>
                    <span v-if="this.vaData.curr.current_emission !== '0.00'">
                        {{ this.vaData.curr.current_emission }}
                    </span>
                </div>
                <div class="d-flex justify-content-between py-1">
                    <span>{{ this.$t('views.ShowInfoVA.15') }}</span>
                    <span v-if="this.vaData.curr.bind_fiat_curr">
                        {{ this.vaData.curr.bind_custom_count }} 
                        {{ this.vaData.curr.ticker }} = 
                        {{ this.vaData.curr.bind_fiat_count }}
                        {{ this.vaData.curr.bind_fiat_curr_text }}
                    </span>
                </div>
                <div class="d-flex justify-content-between lightgrey py-1">
                    <span>{{ this.$t('views.ShowInfoVA.16') }}</span>
                    <span v-if="this.vaData.curr.cover_percent !== '0.00'">
                        {{ this.vaData.curr.cover_percent }}
                    </span>
                </div>
            </div>
            <div
                class="d-flex flex-column pr-2 py-1"
                :class="{'w-100 mb-2': this.$store.state.resize.w < 576, 'col-4': this.$store.state.resize.w >= 576}"
            >
                <div class="neue-roman color-menu text-uppercase">{{ this.$t('views.ShowInfoVA.8') }}</div>
                <div>{{ this.vaData.curr.category }}</div>
            </div>
            <div
                class="py-1"
                :class="{'w-100 mb-2': this.$store.state.resize.w < 576, 'col-4': this.$store.state.resize.w >= 576}"
            >
                <div class="d-flex flex-wrap">
                    <div class="neue-roman color-menu m-0 mr-2 text-uppercase">{{ this.$t('views.ShowInfoVA.10') }}</div>
                    <div v-for="(item, index) in this.vaData.links" :key="index">
                        <div v-if="item.icon && item.text" class="pointer mr-2" @click.stop="(event) => this.goToLink(event, item.value)">
                            <span class="hidden-label">{{ item.text }}</span>
                            <Icon :name="item.icon" />
                        </div>
                    </div>
                </div>
                <div v-for="(item, index) in this.vaData.links" :key="index">
                    <div v-if="!item.icon && !item.text">
                        {{ item.value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/components/icons/Icon.vue";
import Tooltip from '@/components/service/Tooltip';
export default {
    components: {
        Icon,
        Tooltip
    },
    props: {
        vaData: {
            type: Object,
        }
    },
    methods: {
        goToLink(event, link) {
            console.log('goToLink');
            event.stopPropagation();
            window.open(link, '_blank');
        },
        sized(str) {
            if (this.$store.state.resize.w < 720 && str.length > 7) {
                return this.$lib.getTokenString(str, 2);
            } else if (this.$store.state.resize.w < 1200 && str.length > 20) {
                return this.$lib.getTokenString(str, 3);
            } else if (str.length > 15) {
                return this.$lib.getTokenString(str, 4);
            } 
            return str;
        },
        copyText(event) {
            event.stopPropagation();
            const keyt = this.vaData.curr.owner_keyt_alias ? this.vaData.curr.owner_keyt_alias : this.vaData.curr.owner_keyt;
            console.log(keyt);
            this.$lib.copyText(keyt);
        }
    },
}
</script>
<style lang="scss" scoped>
.lightgrey {
    background: $color-lightgrey-block;
}

.hidden-label {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}
</style>