<template>
  <div class='wrapper' :key="this.$store.state.globalKey">
    <Modal v-if="this.$store.state.modal.action"/>
    <Header v-if="this.$store.state.isLogged && this.$store.state.userData.user_info" />
    <transition @enter="enter" @leave="leave">
      <div v-if="this.$store.state.darkWrapper" class='dark-wrapper'></div>
    </transition>
    <CustomLoader :fullPage="true" v-if="this.$store.state.loader"/>
    <!-- <Loading style="z-index: 8000;" :active.sync="this.$store.state.loader" 
        :can-cancel="false" /> -->
    <div class='page-wrapper' :class="{'webViewBottom': this.$store.statewebView}">
      <router-view  class='pb-20' v-if="this.$store.state.isLogged && this.$store.state.userData.config"/>
    </div>

    <Error 
      v-if="this.$store.state.warning_info.warning_visible" 
      :errorText="this.$store.state.warning_info.warning_text"
      @close="this.pushUnVisible"
    />
  </div>
</template>

<script>
import UseClientJs from '@/includes/fingerprint.js';
// import Loading from 'vue-loading-overlay';
import Modal from '@/components/service/Modal';
import CustomLoader from '@/components/service/CustomLoader';
import Login from '@/views/Login';
import Header from '@/components/service/Header';
import Error from '@/components/service/Error';
import 'vue-loading-overlay/dist/css/index.css';
import { gsap } from "gsap";

export default {
  components : {
    // Loading,
    Modal,
    Login,
    Header,
    Error,
    CustomLoader
  },
  data() {
    return {
    }
  },
  created() {
    this.setMetaColor();
    let client = new UseClientJs();
	  let fingerPrint = client.getRetailFingerptint();
    this.$store.commit('FINGERPRINT', fingerPrint);
    this.$store.commit('RESIZE', {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight
    })
    this.getSize();
    window.addEventListener('resize', this.updateSize);
    this.isLogged();
    let ua = navigator.userAgent;
    // console.log('Rebuild')
    if (ua.includes('ppWebViewApp')) {
      this.$store.commit('WEB_VIEW', 'ppWebViewApp');
      if (ua.includes('ppTopBangs/')) {
        let i = ua.split('ppTopBangs/')[1]; 
        this.$store.commit('WEB_VIEW_STATUS_BAR', i);
      }
    }
  }, 
  mounted() {
    let url = new URL(window.location.href);
    let id = url.searchParams.get("id");
    let crt_num = url.searchParams.get("crt_num");
    let control_code = url.searchParams.get("control_code");
    if (window.location.pathname == "/make_good.php" && id && crt_num && control_code) {
        this.axios.post('DoConfirm', {data: {
                'id':id,
                'crt_num':crt_num,
                'control_code':control_code
            }});
      }
  },
  methods: {
    isLogged() {
      this.$store.dispatch('isLogged', {i18n: this.$i18n});
    },
    setMetaColor() {
      let themeColor = document.querySelector("meta[name='theme-color']");
      themeColor.content = process.env.VUE_APP_THEMECOLOR;
    },
    updateSize() {
      this.$store.commit('RESIZE', {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight
      })
      this.getSize();
    },
    getSize() {
      if (document.documentElement.clientWidth < 576) {
        this.$store.commit('IS_MOBILE', true);
      } else {
        this.$store.commit('IS_MOBILE', false);
      }
    },
    enter(el) {
      gsap.fromTo(el, {opacity: 0}, {opacity: 0.1, duration: 0.2});
    },
    leave(el, done) {
      gsap.fromTo(el, {opacity: 0.1}, {opacity: 0, duration: 0.2, onComplete: done});
    },
    pushUnVisible() {
      this.$store.commit("SET_WARNING", {});
    }
  },
}
</script>

<style lang="scss">
@import "@/includes/_fonts.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: HelveticaNeue, Roboto, Nunito;
  // @media (max-width: 1500px) {
  //   font-size: 14px;
  // }
  // @media (max-width: 1350px) {
  //   font-size: 12px;
  // }
  // @media (max-width: 1120px) {
  //   font-size: 11px;
  // }
  // @media (max-width: 1000px) {
  //   font-size: 10px;
  // }
  word-wrap: break-word;
}
.grey {
  color: $color-grey;
}
.color-menu {
  color: $color-menu;
}
.color-green {
  color: $color-green;
}
.color-system-green {
  color: $color-system-green;
}
.color-acent {
  color: $color-acent;
}
.color-warning { 
  color: $color-warning;
}
.color-newsg {
  color: $color-newsg;
}
.color-yellow {
  color: $color-yellow;
}
.color-invalid {
  color: $color-invalid;
}
.border-grey {
  border-color: $color-grey;
}
.href {
  color: $color-href !important;
}
.max-h-1 {
  max-height: 1rem !important;
}
.h100 {
  height: 100% !important;
}

.bs-tooltip-auto {
  z-index: 5000 !important;
}

.sm-no-p {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.wh-23 {
  @media (min-width: 992px) {
      width: 23%;
  }
}
.wh-74 {
  @media (min-width: 992px) {
      width: 73%;
  }
}
.wh-48 {
  @media (min-width: 992px) {
      width: 48%;
  }
}

a {
  text-decoration: none !important;
  color: #000;
}
b {
  font-weight: bold;
}

body:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100%; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    // background:url($bg-image) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: transparent;
}
.before-bg:after {
  background:url($bg-image) center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.beforew-bg {
  background-color: transparent !important;
}
.afterw-bg {
  background-color: $color-background !important;
}
.t-head-custom > tr > th {
    font-weight: normal;
    text-transform: uppercase;
    color: $color-grey;
    font-size: 12px;
}
.table-striped > tbody.t-body-custom > tr > td {
    border-top: 1px solid $color-lightgrey;
    font-size: 16px;
}
.table-striped > tbody.t-body-custom > tr:nth-of-type(odd) > td {
    background-color: #fff;
    box-shadow: none;
}
.after-bg:after {
  background-color: $color-background !important;
  background-image: none !important;
}
.break-word {
  word-wrap: break-word;
}
.break-all {
  word-break: break-all;
}
#app {
  color: #2c3e50;
  // background-color:transparent;
  min-height: 100%;
  width: 100%;
}
.neue-bold {
  font-family: 'HelveticaNeue bold', 'Roboto bold', sans-serif !important;
}
.neue-roman {
  font-family: 'HelveticaNeue roman', 'Roboto', sans-serif !important;
}
.neue {
  font-family: 'HelveticaNeue', 'Roboto medium', sans-serif !important;
}
.light {
  font-family: "HelveticaNeue light", "Roboto light", sans-serif !important;
}
h4 {
    font-family: 'HelveticaNeue bold', 'Roboto bold', sans-serif;
}
h5, h3 {
  font-family: 'HelveticaNeue roman', 'Roboto medium', sans-serif;
}


.href, .pointer {
  cursor: pointer;
}
.c_default {
  cursor: default;
}
.btn_mb {
  text-align: center !important;
}
.btn_mb button, button.btn_mb {
  width: 90%;
  margin-top: 1rem;
}
.wrapper {
  position: relative;
  min-height: 100vh;
  background-color: $color-background;
}
.dark-wrapper {
  bottom: 0;
  top: 0;
  width: 100%;
  background-color: #000;
  position: absolute;
  z-index: 2000;
}
.page-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  @media screen and (max-width: 425px) {
    margin-bottom: 100px;
  }
}
.pr {
  margin-top: 42px;
}
.mw34 {
  min-width: 35px;
}
.mw40 {
  min-width: 40px;
}
.mw54 {
  min-width: 54px;
}
.simp-bg {
  background-color: $color-href;
  border: 1px solid $color-href;
}
.k-symbol {
  display: flex;
  justify-content: center;
  align-items: center; 
  span {
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
}
.round-border {
  border-radius: 50%;
}
.table-div {
  div:last-child {
    border: none !important;
  }
}
.content {
  background-color: #FFFFFF;
  box-shadow: 1px 2px 4px 1px rgb(1 52 23 / 10%);
  border-radius: 12px;
  margin-bottom: 2rem;
}
    .buttons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
        padding-bottom: 1rem;
        button {
            margin: 0 0.5rem;
        }
    }

.footer {
    background-color: $color-menu-active;;
    font-size: 1rem;
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
    &-text {
        font-size: 1.1rem;
    }
}
.require {
    color: red;
}
.webViewBottom {
  padding-bottom: 100px;
}
</style>