<template>
  <div class='container-xl container-fluid'>
    <h1>ForTests</h1>
    
    <div class="content p-3">
      <h3 class="neue-bold"> Check SIGN </h3>

      <Input type='textarea' :label="'Pay_URL'" :require="true" id='pay_url' :dataValue='this.pay_url' @updateParentState="this.updateState" />

      <Input type='text' :label="'Skey'" :require="true" id='skey' :dataValue='this.skey' @updateParentState="this.updateState" />
      <div class="d-flex justify-content-center mt-2">
        <Button :name="'Check SIGN'" class='col-sm-12 col-md-4' type='btn-warning btn' v-on:click='this.checkSign'/>
      </div>
    </div>

    <div>
      <input type="text" v-model="this.action">
      <input type="text" v-model="this.method">

      <div class="mb-1">Form</div>

      <form :method="this.method" target="_blank" :action="this.action">
        <input type="text" name="creq" value="aHR0cHM6Ly95YW5kZXgucnU=">
        <button type="submit">Отправить</button>
      </form>
    </div>

    <Error 
      v-if="this.isPushErrorVisible"
      :errorText="'Error No. 1075. Insufficient funds for payment.'"
      @close="this.pushErrorUnVisible"
    />

    <Warning 
      v-if="this.isPushWarningVisible"
      :warningType="'warning-info'"
      :warningText="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'"
      @close="this.pushWarningUnVisible"
    />

    <button v-on:click="this.do_something">Do something</button>

    <button v-on:click="this.pushErrorVisible">Error push</button>
    <button v-on:click="this.pushWarningVisible">Warning push</button>

    <div>
      <input type="text" v-model="this.qr">
      <button v-on:click="this.set_atm_token">Set_atm_token</button>
    </div>
    <h2>Инкассация</h2>
    <div>
      <p>num_point<input type="text" v-model="this.num_point"></p>
      <p>validator_is<input type="text" v-model="this.validator_is"></p>
      <p>collectors<input type="text" v-model="this.collectors"></p>
      <p><button v-on:click="this.addIncassAtm">addIncassAtm</button></p>
    </div>

    <div>
      <p>incass_atm<input type="text" v-model="this.incass_atm"></p>
      <p>num_point<input type="text" v-model="this.num_point"></p>
      <p>collectors<input type="text" v-model="this.collectors"></p>
      <p>validator_is<input type="text" v-model="this.validator_is"></p>
      <p><button v-on:click="this.SetparamsIncassAtmOrder">SetparamsIncassAtmOrder</button></p>
    </div>

    <div>
      <button v-on:click="this.getIncassAtm">getIncassAtm</button>
    </div>

    <div>
      <p>group_status<input type="text" v-model="this.group_status"></p>
      <p>incass_atm<input type="text" v-model="this.incass_atm"></p>
      <p><button v-on:click="this.SetparamsIncassAtmOrderStatus">SetparamsIncassAtmOrderStatus</button></p>
    </div>

    <div>
      <p>num_point<input type="text" v-model="this.num_point"></p>
      <p>accept<input type="text" v-model="this.accept"></p>
      <p><button v-on:click="this.AdmSetAcceptPoint">AdmSetAcceptPoint</button></p>
    </div>

    <h2>Инкассаторы</h2>
    <div>
      <p>collector_name<input type="text" v-model="this.coll_name"></p>
      <p><button v-on:click="this.AddCollector">AddCollector</button></p>
    </div>
    <div>
      <p><button v-on:click="this.GetlistCollectors">GetlistCollectors</button></p>
    </div>

    <button class="mr-1" @click="this.getCountersIncassATMRest('40')">getCountersIncassATMRest status 40</button>
    <button @click="this.getCountersIncassATMRest('50')">getCountersIncassATMRest status 50</button>
  </div>
</template>

<script>
import Input from '@/components/service/Input';
import Button from '@/components/service/Button';
import Error from '@/components/service/Error';
import Warning from '@/components/service/Warning';

export default {
    components: {
      Input,
      Button,
      Error,
      Warning
    },
    data() {
      return {
        action: 'https://gate-a.paypoint.pro/401/test_bank_acs.php',
        method: 'post',
        pay_url: 'https://pay-a.paypoint.pro/proc/do/?ext_transact=automatic!&num_shop=4662&keyt_shop=61000810100000000044&identified=1&sum=22&payform=7709&comment=&free_param=&url_success=http%3A%2F%2Furlsuccess&url_decline=http%3A%2F%2Furldecline&url_callback=&sign=56262a40114869013cca8e2f029bd84a',
        skey: 'eyjwkyN0vqVS1200MPeAA606Jcf2Fi1T7L6424th',
        isPushErrorVisible: false,
        isPushWarningVisible: false, 
        qr: '',
        validator_is: '1',
        num_point: '8',
        accept: '1',
        collectors: '1',
        group_status: '35',
        incass_atm: '12',
        coll_name: 'Василий Пупкин',
        coll_inc: '1'
      }
    },
    mounted() {
      console.log('mounted', this.isPushVisible);
    },
    methods: {
        do_something() {
          this.axios.post('DoSomething')
        },
        checkSign() {
          this.axios.post('TestCheckSign', {data: {
                'pay_url': this.pay_url,
                'skey': this.skey
          }})
        },
        updateState(arg) {
          this[arg.id] = arg.value;
        },
        pushErrorVisible() {
          this.isPushErrorVisible = true;
        },
        pushErrorUnVisible() {
          this.isPushErrorVisible = false;
        },
        pushWarningVisible() {
          this.isPushWarningVisible = true;
        },
        pushWarningUnVisible() {
          this.isPushWarningVisible = false;
        },
        getListATM() {
          
        },
        set_atm_token() {
          this.axios.post('SetATMToken', {data: {atm_token_verify_client: this.qr}, no_loader: true})
        },
        addIncassAtm() {
          this.axios.post('AddIncassAtmOrder', {data: {num_point: this.num_point, validator_is: this.validator_is, collectors: this.collectors}})
        },
        getIncassAtm() {
          this.axios.post('GetlistIncassAtmOrder', {data: {num_point: this.num_point}})
        },
        AdmSetAcceptPoint() {
          this.axios.post('AdmSetAcceptPoint', {data: {num_point: this.num_point, accept: this.accept}})
        },
        SetparamsIncassAtmOrderStatus() {
          this.axios.post('SetparamsIncassAtmOrderStatus', {data: {incass_atm: this.incass_atm, group_status: this.group_status}})
        },
        SetparamsIncassAtmOrder() {
          this.axios.post('SetparamsIncassAtmOrder', {data: {incass_atm: this.incass_atm, validator_is: this.validator_is, num_point: this.num_point, collectors: this.collectors}})
        },
        AddCollector() {
          this.axios.post('AddCollector', {data: {name: this.coll_name}})
        },
        SetparamsCollector() {
          this.axios.post('SetparamsCollector', {data: {inc: this.coll_inc}})
        },
        GetlistCollectors() {
          this.axios.post('GetlistCollectors')
        },
        getCountersIncassATMRest(status) {
          this.axios.post('GetCountersIncassAtmRest', {data: {num_point: this.num_point, group_status: status}})
        }

    }
}
</script>
<style lang="scss" scoped>
  div {
    margin-top: 10px;
  }
</style>
