<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.content.title }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class='table-responsive'>
            <table class="table table-borderless table-striped neue-roman">
                <thead class='t-head-custom'>
                    <tr>
                        <th scope="col" class="align-middle" style="width: 1%;">
                            {{ this.$t('views.CollectorsList.1') }}
                        </th>
                        <th scope="col" class="align-middle" style="width: 15%;">
                            {{ this.$t('views.CollectorsList.2') }}
                        </th>
                        <th scope="col" class="align-middle" style="width: 1%;">
                            {{ this.$t('views.CollectorsList.3') }}
                        </th>
                    </tr>
                </thead>
                <tbody class='t-body-custom'>
                    <tr 
                        v-for="item in this.list" 
                        :key="item.inc" 
                    >
                        <td scope="col" class="align-middle">{{ item.inc }}</td>
                        <td scope="col" class="align-middle">{{ item.name }}</td>
                        <td scope="col" class="align-middle pointer">
                            <i class='item-i edit' v-on:click='() =>{this.editInfoCollector(item)}'></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            close: [Function],
        },
        data() {
            return {
                content: this.$store.state.modal.content,
                list: this.$store.state.modal.content.listCollectors
            }
        },
        methods: {
            editInfoCollector(item) {
                this.$store.commit('MODAL', {
                action: true,
                type: 'InfoCollector',
                content: {
                    title: this.$t('views.CollectorsList.4'),
                    number: item.inc,
                    name: item.name
                }
            })

            },
        }
    }
</script>