<template>
    <div class="d-flex flex-column flex-sm-row mt-3 p-0 mr-0  g-3 align-items-center address" :class="{'mt-0': this.first}">
        <label :for="id" class="col-form-label mt-0 pl-0 pt-0 col-12 col-sm-3 pb-0 pr-1 input-label">{{this.label}}<span class='require' v-if="this.require"> *</span></label>
        <div class="col-12 col-sm-9 mt-0 p-0 address-wrapper">
            <!-- <InputSimple :id="id" :invalid='this.invalid' :invalidHint='this.invalidHint' :autocomplete="this.autocomplete" :type=type :disabled='this.disabled' @blurInput="this.blure" :regCheck='this.regCheck' :dataValue='this.dataValue' @updateParentState="this.setValue"/> -->
            <div
				class='form-control gr-address'
                :class="{'disabled': this.disabled, 'pointer': !this.disabled}"
                :id="id + '_select'"
                v-on:click="this.showParams"
				data-trigger="hover"  data-placement="auto right" data-container="body" data-html="true"
				>
                {{ this.dField.value_text }}
            </div>
            <Tooltip v-if="this.hint" :id="id + '_hint'" :content='this.hint'/>
            <div v-else-if="this.unvisible" class="mw34"></div>
            <Tooltip
                v-if="this.verification"
                :id="id + '_check'"
                :custom="'item-i check'"
                :content="$t('views.InfoReg.3')"
            />
            <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
            <Tooltip v-if="this.unvisible" :id="id + '_va'" :content="$t('views.InfoReg.4')" :htmlContent="true">
                <SwitchSimple
                    :id="id + '_switch_id'"
                    :dataValue='this.unvisibleValue'
                    :disabled="this.is_public == '2' ? true : false"
                    :type="'sm'"
                    class="ml-1"
                    @updateParentState="this.setUnvisibleValue"
                />
            </Tooltip>
        </div>
    </div>
</template> 

<script>
import SwitchSimple from '@/components/service/SwitchSimple';
import Tooltip from '@/components/service/Tooltip';

export default {
    components: {
        Tooltip,
        SwitchSimple
    },
    data() {
        return {
            addr_v: this.addr ? this.addr : {},
            dField: this.field ? this.field : {},
            sizeW: this.$store.state.resize.w,
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
        }
    },
    props: {
        first: {
            type: Boolean,
            default: false,
        },
        id: [String, Boolean],
        label: [String],
        hint: [String, Boolean],
        addr: [Object, Boolean], 
        field: [Object, Boolean], 
        require: [Boolean],
        disabled: [String, Boolean],
        invalid: [Boolean],
        invalidHint: [String, Boolean],
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
        is_public: [Boolean, String],
        verification: [Boolean],
        getAddress: [String],
    },
    methods: {
        findAddressValue(data) {
            // Проверяем: data массив или объект
            if (Array.isArray(data)) {
                for (const item of data) {
                    const result = this.findAddressValue(item);
                    if (result) {
                        return result;
                    }
                }
            } else if (typeof data === 'object' && data !== null) {
                // Если это объект, проверяем его ключи
                if (data.key === 'address') {
                    return data.value;
                }
                // Рекурсивно проверяем все значения объекта
                for (const key in data) {
                    const result = this.findAddressValue(data[key]);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        },
        getlistAddress(valText) {
            if (valText || !this.dField?.value) {
                return;
            }

            this.axios.post('getlistAddress', {data: {house: this.dField.value}}).then(res => {
                if (res.data.body.result == '0') {
                    const address = this.findAddressValue(res.data.body.tables);
                    this.dField.value_text = address;
                }
            })
        },
        setValue(arg) {
            // console.log('setValue address not modal', arg);
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.value
            })
        },
        setTextValue(arg) {
            this.$emit('updateParentTextState', {
                id: arg.id,
                value: arg.value
            })
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', { 
                id: arg.id.replace(/_switch_id/, ''),
                unvisible: true,
                value: arg.value
            })
        },
        showParams() {
            // console.log('showParams, addr_v', this.addr_v);
            this.$store.commit('MODAL', {
                    action: true,
                    type: 'address',
                    content: {
                        field: this.dField,
                        addr: this.addr_v,
                        updateState: (arg, text) => {
                            // console.log('updateState');
                            this.setValue(arg);
                            this.dField.value_text = text;

                            if (arg.new_addr) {
                                this.addr_v = arg.new_addr;
                            }
                        },
                    },
                    }
            );
        },
    },
    created() {
        console.log('created not modal addr', this.addr);
        console.log('created not modal dField', this.dField);
        this.getlistAddress(this.dField.value_text);
    }

}
</script>

<style lang="scss" scoped>
.address {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
    .gr-address {
        resize: none;
        display: inline-block;
        height: auto;
        min-height: 34px;
    }
}
.mt-1 {
    margin: 0 !important;
}
.col-form-label {
    line-height: 1.2 !important;
}

.require {
    color: red;
}
.disabled {
    background-color: #e9ecef;
}

</style>