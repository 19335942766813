<template>
    <button v-if="this.disabled == true"  :style='this.style' v-bind:class="this.type" disabled class='btn disabled neue-roman'>
        <i v-if='this.icon_i' class='item-i mr-1' :class='this.icon_i'></i>
        {{this.name}}
        <slot></slot>
    </button>
    <button v-else class='btn neue-roman' :style='this.style' v-bind:class="this.type">
        <i v-if="this.icon_i.length > 1" class='item-i mr-1' :class='this.icon_i'></i>
        {{this.name}}
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        name: [String],
        type: {
            type: String,
            default: 'btn-primary'
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon_i: {
            type: String,
            default: '',
        },
        style: {
            type: String,
            default: '',
        }
    },
}
</script>

<style lang="scss" scoped>
</style>