<template>
    <div>
    <Datepicker v-if='this.type == "date"'
     v-model="date" locale='ru' 
     :id="this.id"
     :style="'width: 100%;'"
     :format="this.format"
     :placeholder="this.placeholder" 
     autoApply autocomplete="off"
     :maxDate="this.max"
     :minDate="this.min" 
     :enableTimePicker="false"
     :clearable="this.clearable"
     :disabled="this.disabled"
      @update:modelValue="this.setValue">
    </Datepicker>
    <Datepicker v-else-if='this.type == "time" || this.type == "dateTime"' 
     v-model="date" locale='ru' autoApply 
     :format="this.format"
     :id="this.id"
     :style="'width: 100%;'"
     :maxDate="this.max"
     :minDate='this.min' 
     :clearable="this.clearable"
      @update:modelValue="this.setValue" 
      :disabled="this.disabled">
    </Datepicker>
    <input :ref="'inph'" type="hidden" :id="id + '_inph'" value="1549312452000" v-on:change="this.forceChange"/>
    </div>
</template>
<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {ref} from 'vue';

export default {
    components: {
        Datepicker,
    },
    props: {
        id: [String, Boolean],
        dataValue: [String, Boolean],
        type: {
            default: 'date',
            type: [String, Boolean]
        },
        min: {
            default: new Date('1900'),
            type: [Date, Boolean, String]
        },
        max: {
            default: new Date('2100'),
            type: [Date, Boolean, String]
        },
        placeholder: {
            default: '',
            type: [String, Boolean]
        },
        expFormat: { 
            default: false,
            type: [Boolean]
        },
        clearable: {
            default: false,
            type: [Boolean]
        },
        newDate: {
            default: false,
            type: [Boolean]
        },
        disabled: [Boolean],
    },
    watch: {
        dataValue(newd, oldd) {
            let d = this.date
            if (newd != false) {
                this.date = new Date(newd)
            }
        }
    },      
    setup(props) {
        let date = props.newDate ? ref(new Date()) : ''; 
        let format = 'dd.MM.yyyy';

        if (date && props.type == 'date') {
            date = props.dataValue ? ref(new Date(props.dataValue)) : ref(new Date);

        //     format = (date) => {
        //         let day = date.getDate();
        //         let month = date.getMonth() + 1;
        //         let year = date.getFullYear();

        //         return `${day}.${month}.${year}`;
        //     }
        }
        if (date && props.type == 'time') {
            date = ref(new Date);
            format = 'HH:mm';
        }
        if (date && props.type == 'dateTime') {
            date = props.dataValue ? ref(new Date(props.dataValue)) : ref(new Date);
            format = 'yyyy.MM.dd HH:mm';
        }
        // console.log(props.dataValue)
        return {
            format,
            date
        }
    },
    mounted() {
        this.setValue();
    },
    methods: {
        forceChange() {
            this.date = new Date(Number(this.$refs.inph.value));
            console.log(this.date);
            console.log(this.$refs.inph.value);
            this.setValue();
        },
        setValue() {
            let t = ''
            if ((this.type == 'time')) {
                t = new Date();
                t.setHours(this.date.hours, this.date.minutes);
            } else if ((this.type == 'dateTime')) {
                t = this.date ? this.date.toISOString().split('.')[0].replace(/T/gi, ' ') : false; 
            } else {
                if (this.expFormat) {
                    t = this.date ? this.date : false;
                } else {
                    t = this.date ? this.date.toISOString().split('T')[0] : false;
                }
            }
            console.log('input date simple', t);
            this.$emit('updateParentState', {
                id: this.id,
                value: t
            })
        }
    },

}
</script>

<style lang="scss">
.inval {
    input  {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
}
.dp__input {
    border: 1px solid #ced4da !important;
}
.dp__disabled {
    background-color: #e9ecef !important;
    opacity: 1;
}
.dp__disabled:hover {
    border: 1px solid #ced4da !important;
}
</style>