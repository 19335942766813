<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.$t('views.ChoiseKeyt.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="keyts-container">
            <div class="keyts-wrapper">
                <SelectSimple
                    label="Счёт"
                    id="keyt"
                    :dataValue="this.keyt"
                    @updateParentState="this.updateState" 
                    :keywords.sync="this.content.keyts"
                    :keyt="true"
                />
            </div>
        </div>
        <div class="col-12 col-md-3 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button
                :name="this.$t('views.ATM.11')"
                class="col-12 col-sm-6"
                :class='{"mr-1" : this.$store.state.resize.w >= 768, "mb-2" : this.$store.state.resize.w < 768}'
                type='btn-outline-warning'
                @click="this.close"
                :style='"min-width: 108px;"'
            />
            <Button
                :name="this.$t('views.ATM.12')"
                class="col-12 col-sm-6"
                type='btn-warning'
                @click="this.setKeyt()"
                :style='"min-width: 108px;"'
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import InputsRadioGroups from '@/components/service/InputsRadioGroups';
import SelectSimple from '@/components/service/SelectSimple';

export default {
    components: {
        Button,
        InputsRadioGroups,
        SelectSimple
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            keyt: false,
        }
    },
    methods: {
        updateState(arg) {
            this.keyt = arg.value;
        },
        setKeyt() {
            this.content.updateKeyt(this.keyt);
            this.close();
        }
    },
    created() {
        console.log('created this.content.keyts', this.content.keyts);
        console.log('store userKeyts', this.$store.state.userData.keyts);
    }
}
</script>

